<template>
    <div class="steps-contact py-4">
        <div class="container">
            <div class="row">
                <div class="col-auto">
                    <img class="" :src="imgUrl" alt="Ansprechpartner" v-if="imgUrl !== 'null'">
                </div>
                <div class="col">
                    <p>
                        <strong>Ihr zuständiger Gebrauchtmaschinen-Experte {{ fullName }} meldet sich bei Ihnen!</strong>
                        <br>
                        Unser Experte für Baumaschinen in Ihrer Umgebung wird Sie in Kürze zu Ihrer Anfrage kontaktieren.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import contactPlaceholder from '../assets/images/platzhalter-contact.png';

    export default {
        name: 'steps-contact',
        computed: {
            ...mapState({
                contactData: state => state.contactData
            }),
            fullName() {
                return this.contactData.first_name_de + ' ' + this.contactData.last_name_de;
            },
            imgUrl() {
                if (this.contactData.filePicUrl && this.contactData.filePicUrl !== '') {
                    return this.contactData.filePicUrl;
                }
                return contactPlaceholder;
            }
        }
    }
</script>

<style lang="scss">
    .steps-contact {
        img {
            max-width: 85px !important;
            height: auto;
        }
    }
</style>
